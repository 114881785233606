<template>
  <section class="bg-white p-4 mb-5 rounded-sm">
    <h5 class="font-weight-bold mb-3">
      {{ $t(`sparkmembers.contents.views.editor.components.${type}.components.comments-card.title`) }}
    </h5>

    <template v-if="step == 1">
      <p class="font-size-sm">
        {{ $t(`sparkmembers.contents.views.editor.components.${type}.components.comments-card.paragraph.pt1`) }}
        <strong>
          {{
            $t(`sparkmembers.contents.views.editor.components.${type}.components.comments-card.paragraph.pt2`, {
              status: comments
                ? $t(`sparkmembers.contents.views.editor.components.${type}.components.comments-card.paragraph.enabled`)
                : $t(
                    `sparkmembers.contents.views.editor.components.${type}.components.comments-card.paragraph.disabled`
                  ),
            })
          }}</strong
        >
      </p>

      <MXButton class="mt-3" variant="secondary" @click="onChangeComments" data-testid="change-comments-button">{{
        $t(`sparkmembers.contents.views.editor.components.${type}.components.comments-card.buttons.change-navigation`)
      }}</MXButton>
    </template>
    <template v-if="step == 2">
      <RadioInputBox :hide-box="true" class="mt-3" name="comments" v-model="comments" :value="true">
        {{ $t(`sparkmembers.contents.views.editor.components.${type}.components.comments-card.radio.enable-comments`) }}
      </RadioInputBox>
      <RadioInputBox :hide-box="true" class="mt-3" name="comments" v-model="comments" :value="false">
        {{
          $t(`sparkmembers.contents.views.editor.components.${type}.components.comments-card.radio.disabled-comments`)
        }}
      </RadioInputBox>

      <MXButton class="mt-3" variant="secondary" @click="onSave">{{
        $t(`sparkmembers.contents.views.editor.components.${type}.components.comments-card.buttons.save`)
      }}</MXButton>
    </template>
  </section>
</template>

<script>
import MXButton from '@/shared/components/MXButton.vue';
import RadioInputBox from '@/shared/components/RadioInputBox.vue';

export default {
  name: 'CommentsCard',
  components: {
    MXButton,
    RadioInputBox,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      step: 1,
      comments: this.value,
    };
  },
  methods: {
    onChangeComments() {
      this.step++;
    },
    onSave() {
      this.$emit('input', this.comments);
      this.step = 1;
    },
  },
};
</script>
