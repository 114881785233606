import CommentsCard from './CommentsCard';
import { createLocalVue, shallowMount } from '@vue/test-utils';
import flushPromises from 'flush-promises';

function mountWrapper(localVue, stubs, props) {
  return shallowMount(CommentsCard, {
    localVue,
    stubs,
    mocks: {
      $t: jest.fn().mockImplementation(x => x),
    },
    propsData: props,
  });
}

describe('CommentsCard', () => {
  const localVue = createLocalVue();

  let wrapper;
  const stubs = {
    RadioInputBox: {
      template:
        '<div class="radio-input-stub"><input type="radio" :value="value" v-model="modelValue" @change="$emit(\'change\', $event.target.checked)" /></div>',
      props: ['value', 'modelValue'],
    },
    MXButton: {
      template: '<button class="mx-button-stub" @click="$emit(\'click\')"></button>',
      props: ['variant'],
    },
  };

  it('should save with new value', async () => {
    expect.assertions(1);

    wrapper = mountWrapper(localVue, stubs, {
      type: 'lesson',
      value: true,
    });

    await wrapper.find('.mx-button-stub').trigger('click');
    await flushPromises();

    const radioInput = wrapper.find('.radio-input-stub input[type="radio"]');
    await radioInput.setChecked();
    await flushPromises();

    await wrapper.find('.mx-button-stub').trigger('click');
    await flushPromises();

    expect(wrapper.emitted().input[0]).toBeTruthy();
  });
});
